import { BaseViewModelInterface } from './base-view-model.interface';
import { MessageContainer, SourceMessage } from './message-container';
import { BaseError } from '../messages/base-error';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';
import { UUIDHelper } from '@nts/std/src/lib/utility';
import { BehaviorSubject, Subject } from 'rxjs';

export abstract class BaseViewModel implements BaseViewModelInterface {

    protected internalIsRequired = true;
    protected _isEnabled = true;
    protected _isVisible = true;

    // Necessario per identificare univocamente un view model (vedi gestione errori)
    readonly uniqueId = UUIDHelper.generateUUID();
    skipValidation = false;
    onErrorStatusChanged: Subject<void> = new Subject();
    onFocusRequested: Subject<void> = new Subject();

    isVisible$ = new BehaviorSubject<boolean>(this._isVisible);

    destroySubscribers$: Subject<void> = new Subject();
    errors$: BehaviorSubject<Array<string>> = new BehaviorSubject([]);

    private internalParent: BaseViewModelInterface = null;

    get parent() {
        return this.internalParent;
    }
    set parent(value: BaseViewModelInterface) {
        this.internalParent = value;
    }

    get isVisible() {
        return this._isVisible;
    }

    set isVisible(value: boolean) {
        this._isVisible = value;
        this.isVisible$.next(value);
    }

    get isRequired() {
        return this.internalIsRequired;
    }

    get hasErrors(): boolean {
        return false;
    }

    getErrors(): Array<string>  {
        return this.errors$.value;
    }

    get hasDecodeError() {
        return false;
    }

    get isEnabled() {
        return this._isEnabled;
    }

    set isEnabled(value) {
        this._isEnabled = value;
    }

    get messageContainerCollection(): Array<MessageContainer> {
        return this._messageContainerCollection;
    }

    private _messageContainerCollection = new Array<MessageContainer>();

    onDestroy() {
        if (this._messageContainerCollection?.length > 0) {
            this._messageContainerCollection = [];
            if (this['eventDispatcher']) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onClearMessagesInViewModel.next(this);
            }
        }
        this.destroySubscribers$.next();
    }

    clearErrors() {

    }

    validateProperty(propertyName: string): void {

    }

    validate() {

    }

    addError(sourceMessage: SourceMessage, error: BaseError) {
        let add = true;
        this.messageContainerCollection.forEach((mc) => {
            if (mc.contains(error)) {
                add = false;
            }
        });
        if (add) {
            this.messageContainerCollection.splice(0, 0, new MessageContainer(error, sourceMessage, this.uniqueId));
            if (this['eventDispatcher']) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onAddMessageInViewModel.next({ viewModel: this, messages: [error] });
            }
        }
        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();
    }

    // TODO Tommy verificare se devo lanciare l'evento per aggiornare i servizi
    removeError(item: MessageContainer) {
        this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        if (this['eventDispatcher']) {
            const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
            ed.onRemovedMessageInViewModel.next(this);
        }
        // this.propertyViewModelChanged.emit();
        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();
    }

    removeMessage(item: MessageContainer) {
        this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        if (this['eventDispatcher']) {
            const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
            ed.onRemovedMessageInViewModel.next(this);
        }
    }

    protected removeMessagesFromCondition(condition: (msg: MessageContainer) => boolean) {
        const list = this.messageContainerCollection.filter(condition);
        list.forEach((item) => {
            this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        });
    }

    protected removeMessagesFromSource(sourceMessage: SourceMessage) {
        let errorsChanged = false;
        const list = this.messageContainerCollection.filter(x => x.sourceMessage === sourceMessage);
        list.forEach((item) => {
            if (item.isErrorMessage) {
                errorsChanged = true;
            }
            this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        });
        if (this['eventDispatcher']) {
            const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
            ed.onRemovedMessageInViewModel.next(this);
        }
        if (errorsChanged) {
            this.updateCurrentErrors();
            this.onErrorStatusChanged.next();
        }
    }

    protected removeAllMessages() {
        this._messageContainerCollection = [];
        if (this['eventDispatcher']) {
            const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
            ed.onClearMessagesInViewModel.next(this);
        }
        this.errors$.next([]);
        this.onErrorStatusChanged.next();
    }

    updateCurrentErrors() {
        const errors = this.messageContainerCollection.filter(x => x.isErrorMessage).map(x => x.message);
        this.errors$.next(errors);
    }
}
