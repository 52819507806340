import { StringPropertyViewModel } from './string-property-view-model';
import { MasterViewModelInterface } from '../master-view-model.interface';
import { BehaviorSubject } from 'rxjs';
import { MessageResourceManager } from '../../resources';

export class SearchStringPropertyViewModel extends StringPropertyViewModel {

    override canNotifyModified = false;
    override trimDx = false;
    override instantModelChange = true;

    isVisibleExtendedFilterTitle$ = new BehaviorSubject<boolean>(true);

    extendedFilterTitle: string = MessageResourceManager.Current.getMessage("ExtendedFilterTitle");

    override get value(): string {
        return this._value;
    }
    override set value(updatedValue: string) {
        if (this.value !== updatedValue) {
            this._value = updatedValue;
            this.validate();
            this.onPropertyChanged(this.bindedValuePropertyName);
        }
    }

    private _value = '';
    private _masterViewModel: MasterViewModelInterface;

    constructor(init, mvm: MasterViewModelInterface) {
        super(init);
        this._masterViewModel = mvm;
    }

    execSearch(val) {
        this._masterViewModel.execSearch(val, true);
    }
}
