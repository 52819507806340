import { Expose } from "../../serialization";
import { Filter } from "../find-options/filter";



export class Where {

    constructor(){
        this.filter = new Filter();
    }

    @Expose()
    position: number;

    @Expose()
    operator: WhereOperators;

    @Expose()
    filter: Filter;
}

export enum WhereOperators {
    And,
    Or,
    OpenParenthesis,
    CloseParenthesis,
    Filter
}

export enum SetOperatorInWhere {
    And,
    Or,
    None
}
